import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure this comes before any custom CSS imports

// Custom Styles
import './App.css';
import './styles/NavBar.css';

import './styles/MainBar.css';
import './styles/SectionHeader.css';

import ContactList from './ContactList';
import CatalogList from './CatalogList';
import LogIn from './LogIn';
import Register from './Register';
import Profile from './Profile';
import PrivateRoute from './PrivateRoute';
import logo from './subzy-logo.png';
import Success from './Success';
import Reauth from './Reauth';
import NavBar from './NavBar'; // Import the NavBar component
import PaymentSuccess from './PaymentSuccess';
import OnboardingFlow from './OnboardingFlow';
import ResetPassword from './ResetPassword.js';
import PaymentList from './PaymentList';
import PaymentSettings from './PaymentSettings';
import Reports from './Reports';
import Schedule from './Schedule';
import PersonalInfoSettings from './PersonalInfoSettings';
import SelectPlan from './SelectPlan';
import { AuthProvider, useAuth } from './contexts/AuthContexts';

import 'react-toastify/dist/ReactToastify.css';
import './styles/CustomToast.css';
import { ToastContainer } from 'react-toastify';  // Add this import

// Correct import for the user icon
import { ReactComponent as UserIcon } from './icons/user.svg';

const App = () => {
  const [siteVersion, setSiteVersion] = useState('');
  const location = useLocation();

  useEffect(() => {
    // Determine the site version based on the URL
    const hostname = window.location.hostname;
    if (hostname === 'localhost' || hostname === '127.0.0.1') {
      setSiteVersion('LOCAL');
    } else if (hostname.includes('demo')) {
      setSiteVersion('DEMO');
    } else if (hostname.includes('beta')) {
      setSiteVersion('BETA');
    }
  }, []);

  const getVersionStyle = () => {
    let backgroundColor;
    switch (siteVersion) {
      case 'DEMO':
        backgroundColor = '#CDB8E9'; // Orange
        break;
      case 'BETA':
        backgroundColor = '#B8DB8A'; // Green
        break;
      case 'LOCAL':
        backgroundColor = '#EA9280'; // Dodger Blue
        break;
      default:
        backgroundColor = '#C9C9C9'; 
    }
    return { backgroundColor, color: 'white' };
  };

  return (
    <div className="container">
      <div className="main-bar">
        <a href="/">
          <img className="logo" src={logo} alt="subzy" />
        </a>
        <div className="version-profile-container">
          {siteVersion && (
            <p className="version-text" style={getVersionStyle()}>
              {siteVersion}
            </p>
          )}
          {!['/login', '/signup', '/reset-password','/'].includes(location.pathname) && (
            <Link to="/profile" className="profile-icon" style={getVersionStyle()}>
              <UserIcon />
            </Link>
          )}
        </div>
      </div>
      <NavBar /> 
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/success" element={<Success />} />
        <Route path="/reauth" element={<Reauth />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/spei-pay" element = {<speiData />}> </Route>
        <Route path="/reset-password" element = {<ResetPassword />} />
        <Route path="/select-plan" element={<SelectPlan />} />
        


        <Route element={<PrivateRoute />}>
          <Route path="/members" element={<ContactList />} />
          <Route path="/classes" element={<CatalogList />} />
          <Route path="/payment-list" element = {<PaymentList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/onboarding" element={<OnboardingFlow />} />
          <Route path="/settings/payments" element={<PaymentSettings />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/settings/personal" element={<PersonalInfoSettings />} />


        </Route>
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        toastClassName="custom-toast"
        containerId="toast-container"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default App;
