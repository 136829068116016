import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { RRule } from 'rrule';
import { getAuth } from 'firebase/auth'; 
import './styles/AddMemberModal.css';// Add this import
import { formatInTimeZone, toDate } from 'date-fns-tz';
import { startOfDay } from 'date-fns';
import { toZonedTime, format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const auth = getAuth(); // Add this line

const AddEventModal = ({ show, handleClose, handleSave, selectedDate, classes, activeMembers }) => {
  const [eventData, setEventData] = useState({
    event_title: '',
    start: '',
    end: '',
    classId: '',
    memberId: '',
    recurrence: 'none',
    recurrenceEndDate: '',
  });

  useEffect(() => {
    if (show) {
      const timeZone = 'America/Mexico_City';
      const now = new Date();
      const mexicoCityDate = toZonedTime(now, timeZone);
      
      const startDate = new Date(mexicoCityDate);
      startDate.setHours(9, 0, 0, 0);
      const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour later

      const defaultRecurrenceEndDate = new Date(startDate);
      defaultRecurrenceEndDate.setMonth(defaultRecurrenceEndDate.getMonth() + 1);

      setEventData(prev => ({
        ...prev,
        start: formatDateTimeForInput(startDate, timeZone),
        end: formatDateTimeForInput(endDate, timeZone),
        recurrenceEndDate: formatDateForInput(defaultRecurrenceEndDate, timeZone)
      }));
    }
  }, [show]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData(prev => ({ ...prev, [name]: value }));
  };

  const handleRecurrenceChange = (e) => {
    const { value } = e.target;
    setEventData(prev => ({ ...prev, recurrence: value }));
  };

  const generateRRule = () => {
    if (eventData.recurrence === 'none') return null;

    const timeZone = 'America/Mexico_City';
    const startDate = toDate(parseISO(eventData.start), { timeZone });
    const endDate = toDate(parseISO(eventData.recurrenceEndDate + 'T23:59:59'), { timeZone });

    console.log('Generating RRule');
    console.log('Start date:', startDate);
    console.log('End date:', endDate);
    console.log('Recurrence:', eventData.recurrence);

    let options = {
      freq: RRule[eventData.recurrence.toUpperCase()],
      interval: 1,
      dtstart: startDate,
      until: endDate
    };

    if (eventData.recurrence === 'weekly') {
      options.byweekday = [RRule[['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][startDate.getDay()]]];
    }

    const rule = new RRule(options);
    const ruleString = rule.toString();
    console.log('Generated RRule:', ruleString);
    return ruleString;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const rrule = generateRRule();
    const timeZone = 'America/Mexico_City';
    const formattedEventData = {
      ...eventData,
      rrule: rrule,
      start: formatInTimeZone(new Date(eventData.start), timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
      end: formatInTimeZone(new Date(eventData.end), timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
    };
    handleSave(formattedEventData);
    handleClose();
  };

  function formatDateTimeForInput(date, timeZone) {
    return format(toZonedTime(date, timeZone), "yyyy-MM-dd'T'HH:mm", { timeZone });
  }

  // Add this helper function
  function formatDateForInput(date, timeZone) {
    return format(toZonedTime(date, timeZone), 'yyyy-MM-dd', { timeZone });
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              name="event_title"
              value={eventData.event_title}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Comienza</Form.Label>
            <Form.Control
              type="datetime-local"
              name="start"
              value={eventData.start}
              onChange={handleInputChange}
              required
              step="900" // Add this line
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Termina</Form.Label>
            <Form.Control
              type="datetime-local"
              name="end"
              value={eventData.end}
              onChange={handleInputChange}
              required
              step="900" // Add this line
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Asocia un servicio (opcional)</Form.Label>
            <Form.Control
              as="select"
              name="classId"
              value={eventData.classId}
              onChange={handleInputChange}
              required
            >
              <option value="">Elige un servicio...</option>
              {classes.map(cls => (
                <option key={cls.id} value={cls.id}>{cls.class_name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Asocia un cliente (opcional)</Form.Label>
            <Form.Control
              as="select"
              name="memberId"
              value={eventData.memberId}
              onChange={handleInputChange}
              required
            >
              <option value="">Selecciona un cliente...</option>
              {activeMembers.map(member => (
                <option key={member.id} value={member.id}>{member.contact_name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Recurrencia del evento</Form.Label>
            <Form.Control
              as="select"
              name="recurrence"
              value={eventData.recurrence}
              onChange={handleRecurrenceChange}
            >
              <option value="none">Único</option>
              <option value="daily">Diario</option>
              <option value="weekly">Semanal</option>
              <option value="monthly">Mensual</option>
            </Form.Control>
          </Form.Group>
          {eventData.recurrence !== 'none' && (
            <Form.Group>
              <Form.Label>Fin de la recurrencia</Form.Label>
              <Form.Control
                type="date"
                name="recurrenceEndDate"
                value={eventData.recurrenceEndDate}
                onChange={handleInputChange}
                required={eventData.recurrence !== 'none'}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-column align-items-stretch w-100">
          <Button 
            className="modal-button btn btn-primary w-100" 
            variant="primary" 
            type="submit" 
            onClick={handleSubmit}
          >
            Crear evento
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEventModal;

